import { LocalBusinessJsonLdProps, LogoJsonLdProps } from 'next-seo';
import { OpenGraph } from 'next-seo/lib/types';

export const name = 'CÔNG TY TNHH SX TM DV VĂN PHÒNG PHẨM SƠN THỦY';
export const address = '90 Phan Xích Long, Phường 16, Quận 11, TP. HCM';
export const telephone = '0707467697';
export const email = 'vanphongphamsonthuy@gmail.com';
export const lang = 'vi';
export const geo = 'Viet Nam';
export const website = 'https://vanphongphamsonthuy.com';
export const siteName = 'vanphongphamsonthuy.com';
export const keywords = 'vanphongphamsonthuy.com';
export const homepageTitle = 'Văn Phòng Phẩm Sơn Thủy - Giá Rẻ Nhất Thị Trường';
export const description =
  'VĂN PHÒNG PHẨM Chuyên Cung Cấp Văn Phòng Phẩm Giá Siêu Rẻ, Giá Sỉ Cực Tốt, Bao Giá Thị Trường, Giấy in, Giấy A4 Giá Sỉ, Văn Phòng Phẩm Trọn Gói, Uy Tín';
export const titleTemplate = `%s | ${keywords}`;
export const logoJSON: LogoJsonLdProps = {
  logo: `${website}/assets/logo/icon.png`,
  url: website,
};

export const localBusinessJSON: LocalBusinessJsonLdProps = {
  telephone,
  description,
  type: 'Store',
  id: siteName,
  name: 'CÔNG TY TNHH SX TM DV VĂN PHÒNG PHẨM SƠN THỦY',
  address: {
    streetAddress: '90 Phan Xích Long, Phường 16, Quận 11, TP. HCM',
    addressLocality: 'TP.HCM',
    addressRegion: 'TP.HCM',
    postalCode: '7000',
    addressCountry: 'VN',
  },
  priceRange: '$',
};

export const socialJson = {
  type: 'Organization',
  name: 'Văn Phòng Phẩm Sơn Thủy',
  url: website,
  sameAs: ['https://www.facebook.com/vanphongphamsonthuy'],
};

export default {
  titleTemplate,
  keywords,
  description,
  // title: keywords,
  defaultTitle: keywords,
  openGraph: {
    title: keywords,
    description,
    type: 'website',
    locale: 'vi_VN',
    url: website,
    site_name: siteName,
  } as OpenGraph,
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};
